import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExpensesDebt, HotelsDebt, RestaurantsDebt, ServicesDebt, TravelsDebt } from '../../types/debt.types';

interface DebtState {
  expensesDebt: ExpensesDebt;
  restaurantsDebt: RestaurantsDebt;
  travelsDebt: TravelsDebt;
  hotelsDebt: HotelsDebt;
  servicesDebt: ServicesDebt;
}

const expensesDebtInitialState: ExpensesDebt = {
  building: null,
  apartment: null,
  currency: 'Seleccionar',
  amount: null,
  country: 'Seleccionar',
};

const restaurantsDebtInitialState: RestaurantsDebt = {
  restaurantEmployee: null,
  invoice: null,
  currency: 'Seleccionar',
  amount: null,
  tipPercentage: 0,
  country: 'Seleccionar',
};

const travelsDebtInitialState: TravelsDebt = {
  agency: null,
  booking: null,
  currency: 'Seleccionar',
  amount: null,
  country: 'Seleccionar',
};

const hotelsDebtInitialState: HotelsDebt = {
  hotelName: null,
  currency: 'Seleccionar',
  amount: null,
  country: 'Seleccionar',
};

const servicesDebtInitialState: ServicesDebt = {
  commerceName: null,
  currency: 'Seleccionar',
  amount: null,
  serviceInvoice: '',
  country: 'Seleccionar',
};

const initialState: DebtState = {
  expensesDebt: expensesDebtInitialState,
  restaurantsDebt: restaurantsDebtInitialState,
  travelsDebt: travelsDebtInitialState,
  hotelsDebt: hotelsDebtInitialState,
  servicesDebt: servicesDebtInitialState,
};

const debtSlice = createSlice({
  name: 'debt',
  initialState,
  reducers: {
    setExpensesDebt: (state: DebtState, action: PayloadAction<ExpensesDebt>) => {
      state.expensesDebt = action.payload;
    },
    setRestaurantsDebt: (state: DebtState, action: PayloadAction<RestaurantsDebt>) => {
      state.restaurantsDebt = action.payload;
    },
    setTravelsDebt: (state: DebtState, action: PayloadAction<TravelsDebt>) => {
      state.travelsDebt = action.payload;
    },
    setHotelsDebt: (state: DebtState, action: PayloadAction<HotelsDebt>) => {
      state.hotelsDebt = action.payload;
    },
    setServicesDebt: (state: DebtState, action: PayloadAction<ServicesDebt>) => {
      state.servicesDebt = action.payload;
    },
    resetDebtState: () => initialState,
  },
});

const { reducer } = debtSlice;

export const {
  setExpensesDebt,
  setRestaurantsDebt,
  setTravelsDebt,
  setHotelsDebt,
  setServicesDebt,
  resetDebtState,
} = debtSlice.actions;

export default reducer;
