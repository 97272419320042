import { TFunction } from 'i18next';
import * as Yup from 'yup';
import i18next from '../i18n/config';

const t: TFunction = i18next.t.bind(i18next);

const requiredField: string = t('validations.requiredField');
const notValidEmail: string = t('validations.notValidEmail');
const onlyNumbers: string = t('validations.onlyNumbers');
const onlyLetters: string = t('validations.onlyLetters');
const onlyPositiveNumbers: string = t('validations.onlyPositiveNumbers');
const lengthName: string = t('validations.lengthName');
const lengthPhone: string = t('validations.lengthPhone');
const lengthDocument: string = t('validations.lengthDocument');
const lengthDocumentCPF: string = t('validations.lengthDocumentCPF');
const lengthDocumentPSP: string = t('validations.lengthDocumentPSP');
const lengthDecimalNumber: string = t('validations.lengthDecimalNumber');

const decimalValidation = Yup.number()
  .required(requiredField)
  .typeError(requiredField)
  .positive(onlyPositiveNumbers)
  .test('decimal', lengthDecimalNumber, (value: any) => {
    if (value !== undefined) {
      return /^\d+(\.\d{0,2})?$/.test(value);
    }
    return true;
  });

const UserSchema = Yup.object().shape({
  userName: Yup.string()
    .matches(/^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]+$/, onlyLetters)
    .required(requiredField)
    .typeError(requiredField)
    .min(2, lengthName)
    .max(50, lengthName),
  email: Yup.string()
    .email(notValidEmail)
    .max(100, notValidEmail)
    .required(requiredField)
    .typeError(requiredField),
  phone: Yup.string()
    .matches(/^[0-9]+$/, onlyNumbers)
    .required(requiredField)
    .typeError(requiredField)
    .min(4, lengthPhone),
  documentType: Yup.string().oneOf(['CI', 'PSP', 'DNI', 'CPF'], requiredField).required(requiredField),
  document: Yup.string().when('documentType', {
    is: 'PSP',
    then: Yup.string()
      .required(requiredField)
      .typeError(requiredField)
      .min(5, lengthDocumentPSP)
      .max(50, lengthDocumentPSP),
    otherwise: Yup.string().when('documentType', {
      is: 'DNI',
      then: Yup.string()
        .matches(/^[0-9]+$/, onlyNumbers)
        .min(7, lengthDocument)
        .max(8, lengthDocument)
        .typeError(requiredField)
        .required(requiredField)
        .typeError(requiredField),
      otherwise: Yup.string().when('documentType', {
        is: 'CPF',
        then: Yup.string()
          .matches(/^[0-9]+$/, onlyNumbers)
          .min(10, lengthDocumentCPF)
          .max(11, lengthDocumentCPF)
          .typeError(requiredField)
          .required(requiredField)
          .typeError(requiredField),
        otherwise: Yup.string()
          .matches(/^[0-9]+$/, onlyNumbers)
          .min(7, lengthDocument)
          .max(8, lengthDocument)
          .typeError(requiredField)
          .required(requiredField)
          .typeError(requiredField),
      }),
    }),
  }),
});

const EmailSchema = Yup.object().shape({
  email: Yup.string().email(notValidEmail).max(100, notValidEmail),
});

const ExpensesDebtSchema = Yup.object().shape({
  building: Yup.string().required(requiredField).typeError(requiredField),
  apartment: Yup.string().required(requiredField).typeError(requiredField),
  currency: Yup.string().oneOf(['UYU', 'USD'], requiredField).required(requiredField),
  amount: decimalValidation,
  country: Yup.string().oneOf(['URY', 'ARG'], requiredField).required(requiredField),
});

const TravelsDebtSchema = Yup.object().shape({
  agency: Yup.string().required(requiredField).typeError(requiredField),
  booking: Yup.string().required(requiredField).typeError(requiredField),
  currency: Yup.string().oneOf(['UYU', 'USD'], requiredField).required(requiredField),
  amount: decimalValidation,
  country: Yup.string().oneOf(['URY', 'ARG'], requiredField).required(requiredField),
});

const RestaurantSchema = Yup.object().shape({
  restaurantName: Yup.string().required(requiredField).typeError(requiredField),
  restaurantEmployee: Yup.string().required(requiredField).typeError(requiredField),
});

const RestaurantDebtSchema = Yup.object().shape({
  restaurantEmployee: Yup.string().required(requiredField).typeError(requiredField),
  invoice: Yup.string().required(requiredField).typeError(requiredField),
  currency: Yup.string().oneOf(['UYU', 'USD'], requiredField).required(requiredField),
  amount: decimalValidation,
  tipPercentage: Yup.number(),
  country: Yup.string().oneOf(['URY', 'ARG'], requiredField).required(requiredField),
});

const HotelsDebtSchema = Yup.object().shape({
  hotelName: Yup.string().required(requiredField).typeError(requiredField),
  currency: Yup.string().oneOf(['UYU', 'USD'], requiredField).required(requiredField),
  amount: decimalValidation,
  country: Yup.string().oneOf(['URY', 'ARG'], requiredField).required(requiredField),
});

const ServicesDebtSchema = Yup.object().shape({
  commerceName: Yup.string().required(requiredField).typeError(requiredField),
  currency: Yup.string().oneOf(['UYU', 'USD'], requiredField).required(requiredField),
  amount: decimalValidation,
  serviceInvoice: Yup.string().required(requiredField).typeError(requiredField),
  country: Yup.string().oneOf(['URY', 'ARG'], requiredField).required(requiredField),
});

const schemas = {
  UserSchema,
  EmailSchema,
  ExpensesDebtSchema,
  RestaurantDebtSchema,
  RestaurantSchema,
  TravelsDebtSchema,
  HotelsDebtSchema,
  ServicesDebtSchema,
};

export default schemas;
