import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { linkPaymentData } from '../../../data/linkPayment';
import useBeginPay from '../../../hooks/useBeginPay';
import usePaymentStepper from '../../../hooks/usePaymentStepper';
import useWindowSize from '../../../hooks/useWindowSize';
import { AppDispatch, RootState } from '../../../store/config';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setStep } from '../../../store/slices/step.slice';
import { BeginPay } from '../../../types/beginPay.types';
import { Commerce } from '../../../types/commerce.type';
import { ServicesDebt } from '../../../types/debt.types';
import { PaymentStep, VerticalType } from '../../../types/enums';
import { Issuer } from '../../../types/paymentMethod.types';
import Header from '../../Header';
import CustomSnackbar from '../../Snackbar/CustomSnackbar';
import ConfirmEmailDialog from '../../Stepper/ConfirmEmailDialog';

function ServicesHeader() {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const dispatch: AppDispatch = useAppDispatch();
  const {
    isSubmitDisabled,
    step,
    debtWithFeeFormattedCurrency,
    debtFormattedCurrency,
    handleGoBack,
    isPaymentLink,
  } = usePaymentStepper();
  const [isConfirmEmailDialogOpen, setIsConfirmEmailDialogOpen] = useState<boolean>(false);
  const commerce: Commerce | null = useAppSelector((state: RootState) => state.commerce.commerce);
  const selectedIssuer: Issuer | null = useAppSelector((state: RootState) => state.issuer.issuer);
  const debt: ServicesDebt = useAppSelector((state: RootState) => state.debt.servicesDebt);
  const user = useAppSelector((state: RootState) => state.user);
  const { submitBeginPay, snackbarOpen, errorMessage, closeSnackbar } = useBeginPay({
    country: debt.country,
  });

  const title = `${t('verticals.services.payment-title')}${
    isPaymentLink || step !== 0 ? ' / ' + commerce?.name : ''
  }`;

  const totalAmount =
    step !== 0 ? (selectedIssuer ? debtWithFeeFormattedCurrency : debtFormattedCurrency) : undefined;

  const submitPayment = () => {
    if (selectedIssuer?.issuerConfigurationId) {
      const beginPayValues: BeginPay = {
        verticalType: VerticalType.SERVICES,
        commerceId: +commerce?.commerceId!,
        issuerConfigurationId: selectedIssuer.issuerConfigurationId,
        amount: +(debt.amount ?? 0),
        currency: debt.currency,
        country: debt.country,
        data: {
          userName: user?.userName || linkPaymentData.userName,
          email: user.email || linkPaymentData.email,
          phone: user.phone || linkPaymentData.phone,
          documentType: user.documentType || linkPaymentData.documentType,
          document: user?.document || linkPaymentData.document,
          serviceInvoice: debt.serviceInvoice?.toString(),
        },
      };
      submitBeginPay(beginPayValues);
    }
  };

  const handleConfirmEmailDialogToggle = () => {
    setIsConfirmEmailDialogOpen(!isConfirmEmailDialogOpen);
  };

  const handleSubmit =
    !isPaymentLink && step === PaymentStep.PAYMENT_METHODS
      ? submitPayment
      : isPaymentLink && step === PaymentStep.EMAIL
      ? user.email !== null && user.email !== linkPaymentData.email
        ? submitPayment
        : handleConfirmEmailDialogToggle
      : isPaymentLink && step === PaymentStep.PAYMENT_METHODS
      ? () => dispatch(setStep(PaymentStep.EMAIL))
      : undefined;

  return (
    <>
      <Header
        title={title}
        form={'services-debt-form'}
        hasBack={!!(user.userName && !isPaymentLink) || step !== 0}
        buttonText={
          step === PaymentStep.INFORMATION
            ? t('verticals.step-labels.edit-button')
            : t('verticals.step-labels.back-button')
        }
        goBack={handleGoBack}
        submit={handleSubmit}
        totalAmount={totalAmount}
        hasPIXLogo
        commerceId={commerce?.commerceId}
        verticalType={VerticalType.SERVICES}
      />
      <CustomSnackbar
        message={errorMessage}
        severity="error"
        isOpen={snackbarOpen}
        handleClose={closeSnackbar}
        isMobile={isMobile}
      />
      <ConfirmEmailDialog
        isDialogOpen={isConfirmEmailDialogOpen}
        handleCloseDialog={handleConfirmEmailDialogToggle}
        title={t('verticals.step-labels.email.dialog.title')}
        contentText={t('verticals.step-labels.email.dialog.content')}
        onContinue={submitPayment}
        submitButtonDisabled={isSubmitDisabled}
      />
    </>
  );
}

export default ServicesHeader;
