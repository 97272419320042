import { ChangeEvent } from 'react';

type SetValues = (values: any) => void;

const onCurrencyChange = (event: ChangeEvent<HTMLInputElement>, setValues: SetValues, values: any) => {
  setValues({
    ...values,
    currency: event.target.value,
    amount: null,
  });
};

export const currencyHelper = {
  onCurrencyChange,
};
