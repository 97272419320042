import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import { config } from '../../config/config';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { error, success } from '../../store/slices/payment.slice';
import { PaymentResponse } from '../../types/paymentResult.types';

function BincoPayment() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const payment = useAppSelector((state) => state.payment);
  const iframeRef = React.useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const serverUrl = config.backendUrl!.replace('/api/v1/', '');
    const socket = io(serverUrl);

    const connectFunction = () => {
      console.log('connect');
      socket.emit('handshake', payment.beginPayment?.gatewayReferenceId);
    };

    const successFunction = (paymentSuccess: PaymentResponse) => {
      console.log('Received event:', paymentSuccess);
      dispatch(success(paymentSuccess));
      navigate('/success');
    };

    const errorFunction = (paymentError: PaymentResponse) => {
      // TODO: Remove after Binco fix the error message
      if (paymentError.errorMessage === "The transaction's status is PENDING") {
        return;
      }
      console.log('Received event:', paymentError);
      dispatch(error(paymentError));
      navigate('/error');
    };

    socket.on('connect', connectFunction);
    socket.on('paypunta:success', successFunction);
    socket.on('paypunta:error', errorFunction);

    socket.on('disconnect', () => {
      console.log('Socket disconnected');
    });

    return () => {
      socket.off('connect', connectFunction);
      socket.off('paypunta:success', successFunction);
      socket.off('paypunta:error', errorFunction);
    };
  }, [dispatch, navigate, payment.beginPayment?.gatewayReferenceId]);

  if (!payment.beginPayment?.gatewayUrl) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="iframe-container">
      <iframe
        ref={iframeRef}
        src={payment.beginPayment?.gatewayUrl || 'https://google.com'}
        allow="clipboard-read; clipboard-write"
        width="100%"
        height="100%"
        title="Binco Payment"
      />
    </div>
  );
}

export default BincoPayment;
