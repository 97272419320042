import { ChangeEvent } from 'react';
import { currencies } from '../data/currencies';

type SetFieldValue = (field: string, value: any, shouldValidate?: boolean) => void;
type SetValues = (values: any) => void;

const onCountrySelectChange = (
  event: ChangeEvent<HTMLInputElement>,
  setFieldValue: SetFieldValue,
  setValues: SetValues,
  values: any,
) => {
  const newCountry = event.target.value;
  if (newCountry === 'ARG') {
    setValues({
      ...values,
      amount: null,
    });
    setFieldValue('currency', 'USD');
  }
  setFieldValue('country', newCountry);
};

// TODO: Delete this function when Binco supports FX
const getCurrencyOptions = (values: any) => {
  if (values.country === 'ARG') {
    return currencies.filter((currency) => currency.id === 'USD');
  }
  return currencies;
};

export const countryHelper = {
  onCountrySelectChange,
  getCurrencyOptions,
};
