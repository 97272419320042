import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { BeginPaymentResponse, PaymentResponse } from '../../types/paymentResult.types';

export interface PaymentState {
  beginPayment: BeginPaymentResponse | null;
  payment: PaymentResponse | null;
}

const initialState: PaymentState = {
  beginPayment: null,
  payment: null,
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    beginPaymentSuccess: (state: Draft<PaymentState>, action: PayloadAction<BeginPaymentResponse>) => {
      state.beginPayment = action.payload;
    },
    success: (state: Draft<PaymentState>, action: PayloadAction<PaymentResponse>) => {
      state.payment = action.payload;
    },
    error: (state: Draft<PaymentState>, action: PayloadAction<PaymentResponse>) => {
      state.payment = action.payload;
    },
  },
});

const { reducer } = paymentSlice;

export const { beginPaymentSuccess, success, error } = paymentSlice.actions;

export default reducer;
