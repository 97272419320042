import { useMutation } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { paymentService } from '../services/payment';
import { AppDispatch } from '../store/config';
import { useAppDispatch } from '../store/hooks';
import { beginPaymentSuccess } from '../store/slices/payment.slice';
import { mutationKeys } from '../types/enums';
import usePaymentStepper from './usePaymentStepper';

interface UseBeginPay {
  country: string;
}

function useBeginPay({ country }: UseBeginPay) {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { updateSubmitDisabled } = usePaymentStepper();

  const openSnackbar = useCallback((message: string) => {
    setErrorMessage(message);
    setSnackbarOpen(true);
  }, []);

  const closeSnackbar = useCallback(() => {
    setSnackbarOpen(false);
    setErrorMessage('');
  }, []);

  const mutation = useMutation({
    mutationFn: paymentService.beginPay,
    mutationKey: mutationKeys.beginPay,

    onSuccess: (result) => {
      if (result.error) {
        openSnackbar(result.error);
      } else {
        dispatch(beginPaymentSuccess(result));
        if (country === 'ARG') {
          navigate('/binco-payment');
        } else {
          window.location.href = result.gatewayUrl;
        }
      }
    },
    onError: (error: Error) => {
      openSnackbar(error.message);
    },
    onMutate: () => {
      updateSubmitDisabled(true);
    },
    onSettled: () => {
      updateSubmitDisabled(false);
    },
  });

  return {
    submitBeginPay: mutation.mutate,
    isSubmitting: mutation.isLoading,
    snackbarOpen,
    errorMessage,
    closeSnackbar,
  };
}

export default useBeginPay;
