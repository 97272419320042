import { FormikValues, useFormikContext } from 'formik';
import { BaseSyntheticEvent } from 'react';
import NumberFormat from 'react-number-format';
import { ExpensesDebt } from '../../types/debt.types';
import FormikTextField from './FormikTextField';

interface FormikNumberFormatProps {
  name: string;
  label: string;
  placeholder?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  onChange?: (e: BaseSyntheticEvent) => void;
}

const FormikNumberFormat = ({
  name,
  label,
  fullWidth = false,
  placeholder,
  disabled,
  onChange,
}: FormikNumberFormatProps) => {
  const { values, setValues } = useFormikContext<ExpensesDebt>();
  const handleValueChange = (formattedValues: FormikValues) => {
    setValues({
      ...values,
      [name]: formattedValues.floatValue || '',
    });
  };

  return (
    <NumberFormat
      name={name}
      value={values[name as keyof ExpensesDebt] ?? ''}
      type="tel"
      thousandSeparator="."
      decimalSeparator=","
      onValueChange={handleValueChange}
      customInput={FormikTextField}
      label={label}
      fullWidth={fullWidth}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
    />
  );
};

export default FormikNumberFormat;
