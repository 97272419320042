import { Box, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { ChangeEvent } from 'react';
import { countriesSelectOptions } from '../../data/countries';
import { RootState } from '../../store/config';
import { useAppSelector } from '../../store/hooks';
import colors from '../../styles/_variables.module.scss';
import { Commerce } from '../../types/commerce.type';
import FormikSelectField from '../Formik/FormikSelectField';
import ExclamationCircleIcon from '../Icons/ExclamationCircleIcon';

interface CountrySelectProps {
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  selectedCommerce?: Commerce;
}

function CountrySelect({ onChange, selectedCommerce }: CountrySelectProps) {
  const commerce: Commerce | null = useAppSelector((state: RootState) => state.commerce.commerce);
  const argEnabled = selectedCommerce?.argEnabled || commerce?.argEnabled || false;
  // If argEnabled is false, only Uruguay should be available
  const filteredCountriesOptions = argEnabled
    ? countriesSelectOptions
    : countriesSelectOptions.filter((option) => option.id === 'URY');

  return (
    <Box>
      <FormikSelectField
        name="country"
        fullWidth
        label={t('verticals.hotels.debt-form.labels.country')}
        options={filteredCountriesOptions}
        placeholder={t('verticals.hotels.debt-form.placeholders.country')}
        onChange={onChange}
        disabled={filteredCountriesOptions.length === 1}
      />
      <Stack mt={1} spacing={1} direction="row" alignItems="center">
        <ExclamationCircleIcon />
        <Typography sx={{ fontSize: '0.75rem', color: colors.lightGrey }}>
          {t('verticals.hotels.debt-form.description.country')}
        </Typography>
      </Stack>
    </Box>
  );
}

export default CountrySelect;
